var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.inverter ? _c('div', {
    staticClass: "icon d-flex flex-column justify-content-center",
    class: {
      active: _vm.$route.name == 'inverter'
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'inverter'
        });
      }
    }
  }, [_c('div', {
    staticClass: "text-right"
  }, [_vm._v(" Now "), _c('strong', [_vm._v(_vm._s(_vm.formatPower(_vm.sumNow)))]), _c('br'), _vm._v(" Today " + _vm._s(_vm.sumToday.toFixed(2)) + " ")])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }