<template>
  <div
    v-if="inverter"
    class="icon d-flex flex-column justify-content-center"
    :class="{ active: $route.name == 'inverter' }"
    @click="$router.push({ name: 'inverter' })"
  >
    <!-- <div>D: {{ formatPower(inverter.dom.now) }} ({{ inverter.dom.today }})</div>
    <div>
      G: {{ formatPower(inverter.garaz.now) }} ({{ inverter.garaz.today }})
    </div> -->
    <div class="text-right">
      Now
      <strong>{{ formatPower(sumNow) }}</strong
      ><br />
      Today
      {{ sumToday.toFixed(2) }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'InverterIcon',

  data() {
    return {}
  },

  computed: {
    ...mapState(['inverter']),

    sumNow() {
      return (
        parseFloat(this.inverter.dom.now) +
        parseFloat(this.inverter.dom2.now) +
        parseFloat(this.inverter.garaz.now)
      )
    },

    sumToday() {
      return (
        parseFloat(this.inverter.dom.today) +
        parseFloat(this.inverter.dom2.today) +
        parseFloat(this.inverter.garaz.today)
      )
    }
  },

  methods: {
    formatPower(val) {
      return (val / 1000).toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  padding: 2px 10px 0;
  height: 60px;
  margin: 0 1px;
  display: inline-block;
  transition: background 0.3s ease;

  font-size: 12px;

  cursor: pointer;

  &.active {
    background: rgba($color: #000000, $alpha: 0.3);
  }
}
</style>
